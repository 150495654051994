import { FieldDataType } from 'o365-utils';

/**
 * Gets input type from provided param type.
 * 
 * @param dataType Param type in SQL.
 * @returns Converted input type.
 */
export function getPreviewParamInputType(dataType: FieldDataType) {
    switch (dataType) {
        case 'int':
        case 'decimal':
        case 'float':
        case 'real':
        case 'bigint':
        case 'smallint':
        case 'tinyint':
            return 'number';
        
        case 'bit':
            return 'checkbox';
        
        case 'date':
            return 'date';

        case 'time':
            return 'time';

        case 'datetime':
        case 'datetime2':
            return 'datetime';


        default:
            return "text"; // fallback input type
    }
}